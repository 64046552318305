import React, {useState} from 'react';
import Layout from "../../components/common/layout/Layout";
import {Button, Form, Row} from "react-bootstrap";
import {useSocket} from "../../customHooks/useSocket";
import {useLocation, useNavigate} from "react-router-dom";
import ContentForm from "../../components/contentForm/ContentForm";
import TextEditor from "../../components/common/textEditor/TextEditor";
import ModalMsg from "../../components/common/modal/Modal";
import login from "../login/Login";

const UpdateNews = () => {
    const [socketHandler] = useSocket();
    const [showModal, setShowModal] = useState(false);
    const [formData, setFormData] = useState();
    const location = useLocation();
    const navigate = useNavigate();
    const handleFormSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        const data = Object.fromEntries(formData.entries());
        data.id = location.state.id;
        data.language = location.state.language;
        data.date = location.state.date;
        setFormData(data);
        setShowModal(true);
    }
    const handleConfirm = () => {
        setShowModal(false);
        console.log(formData);
        socketHandler.emit("updateNews", formData);
        socketHandler.onMessage("success", () => {
            navigate("../");
        });
    };
    const handleCancel = () => {
        navigate("../");
    };
    return (
        <Layout>
            <h3>Update News</h3>
            <Form onSubmit={handleFormSubmit}>
                <Row>
                    <Form.Group className="mb-3" controlId="updateNews.Input1">
                        <Form.Label>Title</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="title"
                            name="title"
                            defaultValue={location.state.title}
                        />
                    </Form.Group>
                </Row>
                <Row>
                    <TextEditor defaultValue={location.state.description}/>
                </Row>
                <Button className="my-3 px-5" type={"submit"}>
                    Update News
                </Button>
                <Button
                    className="my-3 mx-2 px-5"
                    variant="secondary"
                    onClick={handleCancel}
                >
                    Cancel
                </Button>
            </Form>
            <ModalMsg
                showModal={showModal}
                handleConfirm={handleConfirm}
                handleClose={() => setShowModal(false)}
            />
        </Layout>
    );
};

export default UpdateNews;