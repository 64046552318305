import React, {
  useState,
  useCallback,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import {InputGroup, FormControl, Spinner} from "react-bootstrap";
import { useDropzone } from "react-dropzone";
import "./fileUploader.css";

const FileUploader = forwardRef(
  ({ title, handleFileUpload, disabled, name, loading }, ref) => {
    const [dropedFile, setDropedFile] = useState();

    useEffect(() => {
      handleFileUpload(dropedFile);
    }, [dropedFile]);

    const onDrop = useCallback((acceptedFiles) => {
      setDropedFile(acceptedFiles[0]);
    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
      onDrop,
      // accept: ".xlsx",
    });

    const handleFileChange = (val) => {
      setDropedFile(val);
    };

    useImperativeHandle(ref, () => ({
      ResetFileUploader() {
        setDropedFile(null);
      },
    }));

    return (
      <div className="file-uploader mt-3" {...getRootProps()}>
        {/* <span>File Upload</span> */}
        <div className="input-file-container">
          <InputGroup
            className="input-file d-flex align-items-center justify-content-between"
            data-testid="file-uploader"
          >
            <span
              className={`${dropedFile?.name ? "violet" : "low-opacity"} ${
                disabled ? "disabled" : ""
              }`}
            >
              {dropedFile?.name || title}
            </span>
            <FormControl
              disabled={disabled}
              aria-label="Small"
              type="file"
              aria-describedby="inputGroup-sizing-sm"
              className="custom-file-input"
              id="report-file"
              name={name}
              onChange={(e) => handleFileChange(e.target.files[0])}
              {...getInputProps()}
            />
              {
                  loading && <Spinner className={'rounded-circle'} variant={'primary'} animation="border" size="sm" />
              }
          </InputGroup>
        </div>
        {isDragActive && <div className="drop-overlay" />}
      </div>
    );
  }
);

export default FileUploader;
