import "./App.css";
import Content from "pages/content/Content";
import "bootstrap/dist/css/bootstrap.min.css";
import { Route, Routes, Navigate } from "react-router-dom";
import Login from "./pages/login/Login";
import ProtectedRoute from "./components/protectedRoute/ProtectedRoute";
import TradingIdeas from "./pages/tradingIdeas/TradingIdeas";
import CreateTradeIdea from "./pages/tradingIdeas/CreateTradeIdea";
import UpdateTradeIdea from "./pages/tradingIdeas/UpdateTradeIdea";
import Notification from "./pages/notification/Notification";
import AlertProvider from "./components/common/alertProvider/AlertProvider";
import News from "./pages/news/News";
import UpdateNews from "./pages/news/UpdateNews";

function App() {
  const token = localStorage.getItem("tk");
  return (
    <div className="App h-100">
      <AlertProvider>
        <Routes>
          <Route
            path=""
            element={
              !token ? <Navigate to="/login" /> : <Navigate to="/content" />
            }
          />
          <Route path="/login" element={<Login />} />
          <Route
            path="/content"
            element={
              <ProtectedRoute>
                <Content />
              </ProtectedRoute>
            }
          />
          <Route path="/trading-ideas">
            <Route
              path=":id"
              element={
                <ProtectedRoute>
                  <UpdateTradeIdea />
                </ProtectedRoute>
              }
            />
            <Route
              path="create"
              element={
                <ProtectedRoute>
                  <CreateTradeIdea />
                </ProtectedRoute>
              }
            />
            <Route
              index
              element={
                <ProtectedRoute>
                  <TradingIdeas />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route
            path="/notification"
            element={
              <ProtectedRoute>
                <Notification />
              </ProtectedRoute>
            }
          />
            <Route path="/news">
                <Route
                    path=":id"
                    element={
                        <ProtectedRoute>
                            <UpdateNews />
                        </ProtectedRoute>
                    }
                />
                <Route
                    index
                    element={
                        <ProtectedRoute>
                            <News />
                        </ProtectedRoute>
                    }
                />
            </Route>
        </Routes>
      </AlertProvider>
    </div>
  );
}

export default App;
