import React, {Fragment, useEffect, useState} from "react";
import { Card, Form } from "react-bootstrap";
import TextEditor from "components/common/textEditor/TextEditor";
import SelectBox from "components/common/selectBox/SelectBox";
import FileUploader from "components/common/fileUploader/FileUploader";
import {useSocket} from "../../customHooks/useSocket";
import moment from "moment";
import {useAxios} from "../../customHooks/useAxios";
import {useAlert} from "../common/alertProvider/AlertProvider";

const ContentForm = ({ inputs, activeContent, resetFormData }) => {
  const [socketHandler] = useSocket();
  const [fetchState, setParams] = useAxios();
  const [files, setFiles] = useState([]);
  const [direction, setDirection] = useState('ltr');
  const showAlert = useAlert();
  const selectOptions = [
    {
      id: null,
      title: "Select a language",
    },
    {
      id: "en",
      title: "En",
    },
    {
      id: "fa",
      title: "Fa",
    },
  ];

  const generateInput = (input) => {
    switch (input) {
      case "title":
        return (
          <Form.Group className="mt-3 mb-3" controlId="exampleForm.ControlInput1">
            <Form.Control
              type="text"
              className={direction === 'rtl' ? `rtl text-right` : ''}
              name={'title'}
              placeholder={direction === 'rtl' ? 'عنوان' : 'Title'}
            />
          </Form.Group>
        );
      case "description":
        return <TextEditor direction={direction}/>;
      case "language":
        return (
          <SelectBox
            className={'mt-3'}
            name={'language'}
            options={selectOptions}
            onChange={(e) => {
              if(e.target.value === 'fa'){
                setDirection('rtl')
              } else {
                setDirection('ltr')
              }
            }}
          />
        );
      case "fileUpload":
        return (
          <FileUploader
            name={'file'}
            title="Upload File"
            loading={fetchState.isLoading}
            handleFileUpload={(value) => {
              const formData = new FormData();
              if(value){
                formData.append('files.file', value);
                formData.append("data", "{}");
                setParams({method: 'POST', url: '/upload-file', data: formData})
              }
            }}
          />
        );
      case "link":
        return (
          <Form.Group className="mt-3" controlId="exampleForm.ControlInput1">
            <Form.Control
              type="text"
              name={'targetLink'}
              placeholder="Link"
            />
          </Form.Group>
        );
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const data = Object.fromEntries(formData.entries());
    data.description = data.description.replace('<p>','');
    data.description = data.description.replace('</p>','');
    data.file = files;
    socketHandler.emit(activeContent.eventName, {...data, date: moment().format()});
    socketHandler.onMessage('success', () => {
      resetFormData();
    });
  };

  useEffect(() => {
    if(!fetchState.isLoading && !fetchState.isError && fetchState.data){
      setFiles(fetchState.data.file);
      showAlert('File uploaded successfully!', 'success');
    }
  }, [fetchState]);

  return (
    <Card className="mt-4 mb-4">
      <Card.Body>
        <Form onSubmit={handleSubmit}>
          {inputs.map((item, index) => (
            <Fragment key={index}>{generateInput(item)}</Fragment>
          ))}
        </Form>
      </Card.Body>
    </Card>
  );
};

export default ContentForm;
