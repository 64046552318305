import React from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";
import "./dropdown.css";

const DropDown = ({ items, onChange, activeItem }) => {
  const handleClick = (id) => {
    onChange(id);
  };

  return (
    <DropdownButton
      variant={"dark"}
      title={activeItem.title}
      className=""
    >
      {items.map((item) => (
        <Dropdown.Item
          key={item.id}
          href="#/action-1"
          onClick={() => handleClick(item)}
          className="w-100"
        >
          {item.title}
        </Dropdown.Item>
      ))}
    </DropdownButton>
  );
};

export default DropDown;
