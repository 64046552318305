import React, { useState } from "react";
import Layout from "components/common/layout/Layout";
import DropDown from "components/common/dropDown/DropDown";
import { DROPDOWN_ITEMS } from "constants/Consts";
import ContentForm from "components/contentForm/ContentForm";
import ModalMsg from "components/common/modal/Modal";
import { Button } from "react-bootstrap";
import { v4 as uuidv4 } from "uuid";
import { PlusCircleFill } from "react-bootstrap-icons";

const Content = () => {
  const [activeContent, setActiveContent] = useState(DROPDOWN_ITEMS[0]);
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState([
    {
      id: uuidv4(),
    },
  ]);

  const formItems = {
    news: ["language", "title", "description"],
    marketInsights: ["language", "description", "fileUpload"],
    weekAhead: ["language", "description", "fileUpload"],
    analysis: ["description", "link"],
  };

  const handleActiveContent = (item) => {
    setActiveContent(item);
    resetFormData();
  };

  const handleAddContent = () => {
    setFormData([...formData, { id: uuidv4() }]);
  };

  const handleSave = () => {
    setShowModal(true);
  };

  const handleConfirm = () => {
    const formElements = document.getElementsByTagName("form") || [];
    for (let i = 0; i < formElements.length; i++) {
      formElements[i].requestSubmit();
    }
    setShowModal(false);
  };

  const handleCancel = () => {
    resetFormData();
  };

  const resetFormData = () => {
    setFormData([
      {
        id: uuidv4(),
      },
    ]);
  };

  return (
    <Layout>
      <div className="d-flex justify-content-between">
        <DropDown
          items={DROPDOWN_ITEMS}
          activeItem={activeContent}
          toggleTitle="Select a content type"
          onChange={handleActiveContent}
        />
        <Button
          variant="outline-primary"
          className="d-flex align-items-center border-0 bg-transparent text-primary"
          onClick={handleAddContent}
        >
          <PlusCircleFill className="me-2" size={24} />
          <strong>Add New</strong>
        </Button>
      </div>
      {formData.map((item) => (
        <ContentForm
          key={item.id}
          activeContent={activeContent}
          inputs={formItems[activeContent.name]}
          resetFormData={resetFormData}
        />
      ))}
      <Button className="my-3 px-5" variant="primary" onClick={handleSave}>
        Save
      </Button>
      <Button
          className="my-3  mx-2 px-5"
          variant="secondary"
          onClick={handleCancel}
        >
        Cancel
      </Button>
      <ModalMsg
        showModal={showModal}
        handleConfirm={handleConfirm}
        handleClose={() => setShowModal(false)}
      />
    </Layout>
  );
};

export default Content;
