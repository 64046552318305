import { useState, useEffect, useReducer } from "react";
import axios from "axios";
import {useAlert} from "../components/common/alertProvider/AlertProvider";
import { useNavigate } from "react-router-dom";

const dataFetchReducer = (state, action) => {
    switch (action.type) {
        case "FETCH_INIT":
            return {
                ...state,
                isLoading: true,
                isError: false,
                id: action.id
            };
        case "FETCH_SUCCESS":
            return {
                ...state,
                isLoading: false,
                isError: false,
                data: action.payload,
            };
        case "FETCH_FAILURE":
            return {
                ...state,
                isLoading: false,
                isError: true,
                data: action.payload,
            };
        default:
            throw new Error();
    }
};

const baseApiEndpoint = 'https://api-main.fortuna.investments';
export const useAxios = (initialParams) => {
    const showAlert = useAlert();
    const navigate = useNavigate();
    const config = {
        mode: 'no-cors',
        headers: {
            "content-type": "application/json"
        },
    };
    const [params, setParams] = useState(initialParams); // {method, url, data}
    const [state, dispatch] = useReducer(dataFetchReducer, {
        isLoading: false,
        isError: false,
        data: initialParams && initialParams.data,
        id: initialParams && initialParams.id,
    });
    useEffect(() => {
        const token = localStorage.getItem("tk");
        if(token){
            axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        } else {
            axios.defaults.headers.common["Authorization"] = ``;
        }
        if (params) {
            const fetchData = async () => {
                dispatch({ type: "FETCH_INIT", id: params.id});
                await axios[params.method.toLowerCase()](
                    baseApiEndpoint + params.url,
                    params.data,
                    config
                )
                    .then((result) => {
                        dispatch({ type: "FETCH_SUCCESS", payload: result.data });
                    })
                    .catch((err) => {
                        dispatch({ type: "FETCH_FAILURE", payload: err });
                        if(err.response.status === 401){
                            localStorage.removeItem('tk');
                            showAlert('Please login!');
                            navigate('/');
                        } else {
                            showAlert('An error occurred while fetching data!');
                        }
                    });
            };

            fetchData();
        }
    }, [params]);

    return [state, setParams];
};
