import React, {useEffect, useState} from 'react';
import Layout from "../../components/common/layout/Layout";
import {useSocket} from "../../customHooks/useSocket";
import {Accordion, Badge, Button, Card, Col, Form, Row, Table} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import moment from 'moment';
import {toUpperFirstChar} from "../../utils/toUpperFirstChar";
import {PencilSquare, PlusCircleFill, PlusSquare} from 'react-bootstrap-icons';
import SelectBox from "../../components/common/selectBox/SelectBox";
import {useAxios} from "../../customHooks/useAxios";

const TradingIdeas = () => {
    const orderStatusList = [
        {
            id: 'active',
            title: 'Active orders'
        },
        {
            id: 'pending',
            title: 'Pending orders'
        },
        {
            id: 'deleted',
            title: 'Deleted orders'
        },
        {
            id: 'closed',
            title: 'Closed orders'
        }
    ]
    const [socketHandler] = useSocket();
    const [items, setItems] = useState([]);
    const [symbolList, setSymbolList] = useState([]);
    const [filters, setFilters] = useState({});
    const [fetchState, setParams] = useAxios({method: 'GET', url: '/symbols', data: {}});
    const navigate = useNavigate();

    useEffect(() => {
        socketHandler.emit('getTradeIdeas', {limit: -1, offset: 0,
            status: filters.status ? [filters.status] : [],
            symbol: filters.symbol ? [filters.symbol] : []});
        socketHandler.onMessage('getTradeIdeas', (message) => {
            setItems(message);
        });
    }, [filters]);

    useEffect(() => {
        if(!fetchState.isLoading && !fetchState.isError && fetchState.data){
            const newArr = fetchState.data.length > 0 ? fetchState.data?.map(item => ({
                ...item,
                title: item.symbol
            })) : []
            setSymbolList(newArr)
        }
    }, [fetchState]);

    const handleFilter = (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        const data = Object.fromEntries(formData.entries());
        setFilters(data);
    }

    return (
      <Layout>
        <div className="d-flex flex-column mb-4">
          <div className="d-flex justify-content-between mb-3">
              <h3>Trade Ideas</h3>
              <Button
                  variant="outline-primary"
                  className='d-flex align-items-center border-0 bg-transparent text-primary'
                  onClick={() => navigate("create")}>
                  <PlusCircleFill className='me-2' size={24}/>
                  <strong>Add New</strong>
              </Button>
          </div>
          <Form onSubmit={handleFilter}>
              <Row>
              <Col>
                  <SelectBox
                      name={'symbol'}
                      options={[{title: 'Filter by Symbol', id: ''}, ...symbolList]}
                  />
              </Col>
              <Col>
                  <SelectBox
                      name={'status'}
                      options={[{title: 'Filter by Status', id: ''}, ...orderStatusList]}
                  />
              </Col>
              <Col>
                  <Button className='me-2' type='submit'>Filter</Button>
                  {
                      (filters.status || filters.symbol) &&
                      <Button variant={'danger'} onClick={() => {setFilters({}); window.location.reload();}}>Clear Filters</Button>
                  }
              </Col>
              </Row>
          </Form>
        </div>
        <Accordion className={'mb-4'}>
          {items.map((item) => (
            <Accordion.Item eventKey={item?.id} key={item?.id}>
              <Accordion.Header>
                <div className="w-100 d-flex flex-column">
                  <div className="my-1"> <span>{`${item?.symbol?.symbol} ${
                    item?.type && toUpperFirstChar(item.type)
                  } at ${item?.price}`}</span>
                    <span>{` (${item.status})`} </span>
                      {
                          item.positionPeriod &&
                          <Badge bg="primary">{item.positionPeriod}</Badge>
                      }
                  </div>
                  <div className="my-1">
                    {moment(item?.published).format("MMM DD,YYYY - HH:mm:ss")}
                  </div>
                </div>
                <PencilSquare
                  className="m-3"
                  size={18}
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate(`${item?.id}`, { state: item })
                  }}
                />
              </Accordion.Header>
              <Accordion.Body>
                <div className="d-flex justify-content-between">
                  <p>
                    <strong>SL:</strong>{" "}
                    <span>{item?.stopLoss ? item?.stopLoss : "-"} </span>
                  </p>
                  <p>
                    <strong>TP:</strong>{" "}
                    <span>{item?.takeProfit ? item?.takeProfit : "-"}</span>
                  </p>
                  <p>
                    <strong>Risk:</strong> <span>{item?.risk}%</span>
                  </p>
                  <p>
                    <strong>Rec. lot size:</strong>{" "}
                    <span>{item?.volume} per 1000$</span>
                  </p>
                </div>
                <div>
                  {item?.update?.split("\n").map((update) => (
                    <li>{update}</li>
                  ))}
                </div>
              </Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
      </Layout>
    );
};

export default TradingIdeas;