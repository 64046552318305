import React, {useEffect, useState} from 'react';
import Layout from "../../components/common/layout/Layout";
import {useSocket} from "../../customHooks/useSocket";
import {Accordion, Tabs, Tab, Form, Row, Table, Spinner} from "react-bootstrap";
import moment from 'moment';
import {PencilSquare, PlusCircleFill, PlusSquare} from 'react-bootstrap-icons';
import { useNavigate } from "react-router-dom";

const News = () => {
    const [socketHandler] = useSocket();
    const [news, setNews] = useState([]);
    const [lang, setLang] = useState('en');
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    useEffect(() => {
        setLoading(true);
        socketHandler.emit('getNews', {limit: -1, offset: 0, language: lang});
        socketHandler.onMessage('getNews', (message) => {
            setLoading(false);
            setNews(message);
        });
    }, [lang]);
    return (
        <Layout>
            <div className="d-flex flex-column mb-4">
                <div className="d-flex justify-content-between mb-3">
                    <h3>News</h3>
                </div>
            </div>
            <Tabs defaultActiveKey="en" id="uncontrolled-tab" className="mb-3" onSelect={(eventName) => setLang(eventName)}>
                <Tab eventKey="en" title="English" />
                <Tab eventKey="fa" title="Farsi" />
            </Tabs>
            {
                loading ?
                    <div className={'w-100 d-flex justify-content-center'}>
                        <Spinner animation={"border"} variant={'primary'}/>
                    </div> :
                    <Accordion className={'mb-4'}>
                        {news.map((item) => (
                            <Accordion.Item eventKey={item?.id} key={item?.id}>
                                <Accordion.Header>
                                    <div className="w-100 d-flex flex-column">
                                        <div className={`fw-bold my-1 ${lang === 'fa' && 'rtl'}`}>
                                            {item.title}
                                        </div>
                                        <div className="my-1">
                                            {moment(item?.date).format("MMM DD,YYYY - HH:mm:ss")}
                                        </div>
                                    </div>
                                    <PencilSquare
                                        className="m-3"
                                        size={18}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            navigate(`${item?.id}`, { state: item })
                                        }}
                                    />
                                </Accordion.Header>
                                <Accordion.Body>
                                    {/*<div className="d-flex justify-content-between">*/}
                                    {/*    */}
                                    {/*</div>*/}
                                    <div className={`news-description ${lang === 'fa' && 'rtl'}`} dangerouslySetInnerHTML={{__html: item.description}} />
                                </Accordion.Body>
                            </Accordion.Item>
                        ))}
                    </Accordion>
            }
        </Layout>
    );
};

export default News;

// import {toUpperFirstChar} from "../../utils/toUpperFirstChar";
// import SelectBox from "../../components/common/selectBox/SelectBox";
// import {useAxios} from "../../customHooks/useAxios";

// const TradingIdeas = () => {
//     const orderStatusList = [
//         {
//             id: 'active',
//             title: 'Active orders'
//         },
//         {
//             id: 'pending',
//             title: 'Pending orders'
//         },
//         {
//             id: 'deleted',
//             title: 'Deleted orders'
//         },
//         {
//             id: 'closed',
//             title: 'Closed orders'
//         }
//     ]
//     const [socketHandler] = useSocket();
//     const [symbolList, setSymbolList] = useState([]);
//     const [filters, setFilters] = useState({});
//     const [fetchState, setParams] = useAxios({method: 'GET', url: '/symbols', data: {}});
//
//     useEffect(() => {
//         socketHandler.emit('getTradeIdeas', {limit: -1, offset: 0,
//             status: filters.status ? [filters.status] : [],
//             symbol: filters.symbol ? [filters.symbol] : []});
//         socketHandler.onMessage('getTradeIdeas', (message) => {
//             setItems(message);
//         });
//     }, [filters]);
//
//     useEffect(() => {
//         if(!fetchState.isLoading && !fetchState.isError && fetchState.data){
//             const newArr = fetchState.data.length > 0 ? fetchState.data?.map(item => ({
//                 ...item,
//                 title: item.symbol
//             })) : []
//             setSymbolList(newArr)
//         }
//     }, [fetchState]);
//
//     const handleFilter = (e) => {
//         e.preventDefault();
//         const formData = new FormData(e.target);
//         const data = Object.fromEntries(formData.entries());
//         setFilters(data);
//     }
//
//     return (
//         <Layout>
//             <Accordion className={'mb-4'}>
//                 {items.map((item) => (
//                     <Accordion.Item eventKey={item?.id} key={item?.id}>
//                         <Accordion.Header>
//                             <div className="w-100 d-flex flex-column">
//                                 <div className="my-1"> <span>{`${item?.symbol?.symbol} ${
//                                     item?.type && toUpperFirstChar(item.type)
//                                 } at ${item?.price}`}</span>
//                                     <span>{` (${item.status})`}</span>
//                                 </div>
//                                 <div className="my-1">
//                                     {moment(item?.published).format("MMM DD,YYYY - HH:mm:ss")}
//                                 </div>
//                             </div>
//                             <PencilSquare
//                                 className="m-3"
//                                 size={18}
//                                 onClick={(e) => {
//                                     e.stopPropagation();
//                                     navigate(`${item?.id}`, { state: item })
//                                 }}
//                             />
//                         </Accordion.Header>
//                         <Accordion.Body>
//                             <div className="d-flex justify-content-between">
//                                 <p>
//                                     <strong>SL:</strong>{" "}
//                                     <span>{item?.stopLoss ? item?.stopLoss : "-"} </span>
//                                 </p>
//                                 <p>
//                                     <strong>TP:</strong>{" "}
//                                     <span>{item?.takeProfit ? item?.takeProfit : "-"}</span>
//                                 </p>
//                                 <p>
//                                     <strong>Risk:</strong> <span>{item?.risk}%</span>
//                                 </p>
//                                 <p>
//                                     <strong>Rec. lot size:</strong>{" "}
//                                     <span>{item?.volume} per 1000$</span>
//                                 </p>
//                             </div>
//                             <div>
//                                 {item?.update?.split("\n").map((update) => (
//                                     <li>{update}</li>
//                                 ))}
//                             </div>
//                         </Accordion.Body>
//                     </Accordion.Item>
//                 ))}
//             </Accordion>
//         </Layout>
//     );
// };