import React from 'react';
import { Nav, Button } from 'react-bootstrap';
import {useLocation, useNavigate} from "react-router-dom";
import './sidebar.css';
import logo from '../../../images/logo.svg';
import {BarChartLine, LayoutTextWindowReverse, Power, Bell, Newspaper} from "react-bootstrap-icons";

const Sidebar = (props) => {
    const location = useLocation();
    const navigate = useNavigate();

    const handleLogout = () => {
        localStorage.removeItem('tk');
        navigate("/");
    }

    return (
        <Nav className={`d-none d-md-block bg-dark sidebar ${props.type === 'mobile' && 'col-md-12 mt-3 sidebar-mobile'}`}
             variant="pills"
             activeKey={location.pathname}
        >
            <div className="d-none d-md-block logo">
                <img src={logo} alt="Fortuna"/>
            </div>
            <Nav.Item className={`my-1`}>
                <Nav.Link className='d-flex align-items-center px-2' href="/content">
                    <LayoutTextWindowReverse className='d-none d-md-block me-3' size={18}/>
                    Content
                </Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link className='d-flex align-items-center px-2' href="/news">
                    <Newspaper className='d-none d-md-block me-3' size={18}/>
                    News
                </Nav.Link>
            </Nav.Item>
            <Nav.Item className={`my-1`}>
                <Nav.Link className='d-flex align-items-center px-2' href="/trading-ideas">
                    <BarChartLine className='d-none d-md-block me-3' size={18}/>
                    Trade Ideas
                </Nav.Link>
            </Nav.Item>
           <Nav.Item className={`my-1`}>
                <Nav.Link className='d-flex align-items-center px-2' href="/notification">
                    <Bell className='d-none d-md-block me-3' size={18}/>
                    Notification
                </Nav.Link>
            </Nav.Item>
            <Button variant="dark" onClick={handleLogout} className="d-block d-md-none d-flex align-items-center w-100 log-out">
                <Power className='me-2' size={18}/>
                Log Out
            </Button>
            <Button variant="dark" onClick={handleLogout} className="d-none d-md-block position-absolute d-flex align-items-center w-100 log-out">
                <Power className='me-2' size={18}/>
                Log Out
            </Button>
        </Nav>

    );
};

export default Sidebar;