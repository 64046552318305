import React, { useState, useEffect } from "react";
import { useSocket } from "../../customHooks/useSocket";
import Layout from "../../components/common/layout/Layout";
import { Form, Button, Row, Col } from "react-bootstrap";
import SelectBox from "../../components/common/selectBox/SelectBox";
import ModalMsg from "components/common/modal/Modal";
import { useLocation, useNavigate } from "react-router-dom";

const UpdateTradeIdea = () => {
  const [socketHandler] = useSocket();
  const location = useLocation();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState();

  const statusOptions = [
    {
      id: "active",
      title: "active",
    },
    {
      id: "pending",
      title: "pending",
    },
    {
      id: "deleted",
      title: "deleted",
    },
    {
      id: "closed",
      title: "closed",
    },
  ];

  const handleUpdate = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const data = Object.fromEntries(formData.entries());
    data.id = location.state.id;
    data.riskFree = !!data.riskFree;
    data.saveProfit = !!data.saveProfit;
    setFormData(data);
    setShowModal(true);
  };

  const handleConfirm = () => {
    setShowModal(false);
    socketHandler.emit("updateTradeIdeas", formData);
    socketHandler.onMessage("success", () => {
      navigate("/trading-ideas");
    });
  };

  const handleCancel = () => {
    navigate("/trading-ideas");
  };

  return (
    <Layout>
      <h3>Update Trade Idea</h3>

      <Form onSubmit={handleUpdate}>
        <Row>
          <Col>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>stopLoss</Form.Label>
              <Form.Control
                type="text"
                placeholder="stop loss"
                name="stopLoss"
                defaultValue={location.state.stopLoss}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>takeProfit</Form.Label>
              <Form.Control
                type="text"
                placeholder="take profit"
                name="takeProfit"
                defaultValue={location.state.takeProfit}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>status</Form.Label>
              <SelectBox
                name={"status"}
                options={statusOptions}
                defaultValue={location.state.status}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>profit</Form.Label>
              <Form.Control
                type="text"
                placeholder="profit"
                name="profit"
                defaultValue={location.state.profit}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>pips</Form.Label>
              <Form.Control
                type="text"
                placeholder="pips"
                name="pips"
                defaultValue={location.state.pips}
              />
            </Form.Group>
          </Col>
          <Col />
        </Row>

        <Form.Group controlId="riskFree">
          <Form.Check type="checkbox" label="risk free" name="riskFree" />
        </Form.Group>
        <Form.Group className="mt-2" controlId="saveProfit">
          <Form.Check type="checkbox" label="save profit" name="saveProfit" />
        </Form.Group>
        <Button className="my-3 px-5" type={"submit"}>
          Update
        </Button>
        <Button
          className="my-3 mx-2 px-5"
          variant="secondary"
          onClick={handleCancel}
        >
          Cancel
        </Button>
      </Form>
      <ModalMsg
        showModal={showModal}
        handleConfirm={handleConfirm}
        handleClose={() => setShowModal(false)}
      />
    </Layout>
  );
};

export default UpdateTradeIdea;
