import socketIOClient from "socket.io-client";
import {useAlert} from "../components/common/alertProvider/AlertProvider";
const socketUrl = 'https://api-main.fortuna.investments/';

export const useSocket = () => {
    const showAlert = useAlert();
    const token = localStorage.getItem('tk');
    const socket = socketIOClient(socketUrl, { query: { token } });
    const defaultArgs = {
        token
    };
    const emit = (event, data) => {
        socket.emit(event, {...defaultArgs, ...data})
    }
    const onMessage = (event, listener) => {
        socket.on(event, listener);
    }
    socket.on('success', () => {
        showAlert('Successful!', 'success')
    });
    const socketHandler = {
        emit,
        onMessage
    }
    return [socketHandler];
};