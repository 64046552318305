import React from 'react';
import {Col, Container, Row, Navbar, Nav} from "react-bootstrap";
import Sidebar from "../sidebar/Sidebar";
import './layout.css'
import {useLocation} from "react-router-dom";
import {locationNames} from "../../../constants/Consts";

const Layout = (props) => {
    const location = useLocation();
    return (
        <Container fluid>
            <Row className="d-block d-md-none position-sticky" style={{top: 0, left: 0, zIndex: 1000}}>
                <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark" className="w-100">
                    <Navbar.Toggle aria-controls="responsive-navbar" />
                    <Navbar.Brand>
                        {locationNames[location.pathname]}
                    </Navbar.Brand>
                    <Navbar.Collapse id="responsive-navbar">
                        <Sidebar type="mobile"/>
                    </Navbar.Collapse>
                </Navbar>
            </Row>
            <Row>
                <Col xs={0} md={2} id="sidebar-wrapper" className="d-none d-md-block pr-0">
                    <Sidebar />
                </Col>
                <Col  xs={12} md={10} id="page-content-wrapper">
                    <div className="pt-3">
                        {props.children}
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default Layout;
