import React, { useState, useEffect } from "react";
import { useSocket } from "../../customHooks/useSocket";
import Layout from "../../components/common/layout/Layout";
import { Form, Button, Row, Col } from "react-bootstrap";
import SelectBox from "../../components/common/selectBox/SelectBox";
import ModalMsg from "components/common/modal/Modal";
import { useAxios } from "../../customHooks/useAxios";
import moment from "moment";
import { useNavigate } from "react-router-dom";

const CreateTradeIdea = () => {
  const navigate = useNavigate();
  const [socketHandler] = useSocket();
  const [fetchState, setParams] = useAxios({
    method: "GET",
    url: "/symbols",
    data: {},
  });
  const [symbolList, setSymbolList] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState();

  const typeOptions = [
    {
      id: "buy",
      title: "buy",
    },
    {
      id: "sell",
      title: "sell",
    },
    {
      id: "buy limit",
      title: "buy limit",
    },
    {
      id: "sell limit",
      title: "sell limit",
    },
    {
      id: "sell stop",
      title: "sell stop",
    },
    {
      id: "buy stop",
      title: "buy stop",
    },
  ];

  const statusOptions = [
    {
      id: "active",
      title: "active",
    },
    {
      id: "pending",
      title: "pending",
    },
  ];
  const positionPeriodOptions = [
    {
      id: "scalp",
      title: "scalp",
    },
    {
      id: "daily",
      title: "daily",
    },
    {
      id: "shortTerm",
      title: "short-term",
    },
    {
      id: "midTerm",
      title: "mid-term",
    },
    {
      id: "swing",
      title: "swing",
    }
  ];

  useEffect(() => {
    if (!fetchState.isLoading && !fetchState.isError && fetchState.data) {
      const newArr =
        fetchState.data.length > 0
          ? fetchState.data?.map((item) => ({
              ...item,
              title: item.symbol,
            }))
          : [];
      setSymbolList(newArr);
    }
  }, [fetchState]);

  const handleCreate = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const data = Object.fromEntries(formData.entries());
    data.symbol = parseInt(data.symbol);
    data.risk = data.risk;
    data.volume = data.volume;
    data.published = moment().format();
    data.pips = null;
    data.profit = null;
    setFormData(data);
    setShowModal(true);
    
  };

  const handleConfirm = () => {
    setShowModal(false);
    socketHandler.emit("createTradeIdeas", formData);
    socketHandler.onMessage("success", () => {
      navigate("/trading-ideas");
    });
  };

  const handleCancel = () => {
    navigate("/trading-ideas");
  };

  return (
    <Layout>
      <h3>Create Trade Idea</h3>

      <Form onSubmit={handleCreate}>
        <Row>
          <Col>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>symbol</Form.Label>
              <SelectBox name={"symbol"} options={symbolList} />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>type</Form.Label>
              <SelectBox name={"type"} options={typeOptions} />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>price</Form.Label>
              <Form.Control type="text" placeholder="price" name="price" />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>stopLoss</Form.Label>
              <Form.Control
                type="text"
                placeholder="stop loss"
                name="stopLoss"
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>takeProfit</Form.Label>
              <Form.Control
                type="text"
                placeholder="take profit"
                name="takeProfit"
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>risk</Form.Label>
              <Form.Control type="text" placeholder="risk" name="risk" />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>volume</Form.Label>
              <Form.Control type="text" placeholder="volume" name="volume" />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>status</Form.Label>
              <SelectBox name={"status"} options={statusOptions} />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Position Period</Form.Label>
              <SelectBox name={"positionPeriod"} options={positionPeriodOptions} />
            </Form.Group>
          </Col>
        </Row>

        <Button className="my-3 px-5" type="submit">
          Save
        </Button>
        <Button
          className="my-3  mx-2 px-5"
          variant="secondary"
          onClick={handleCancel}
        >
          Cancel
        </Button>
      </Form>
      <ModalMsg
        showModal={showModal}
        handleConfirm={handleConfirm}
        handleClose={() => setShowModal(false)}
      />
    </Layout>
  );
};

export default CreateTradeIdea;
