import React, {Fragment, useEffect, useState} from "react";
import { Card, Col, Container, Form, Row, Button, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import {useAxios} from "../../customHooks/useAxios";
import {useAlert} from "components/common/alertProvider/AlertProvider";

const Login = () => {
    const navigate = useNavigate();
    const [username, setUsername] = useState(null);
    const [password, setPassword] = useState(null);
    const showAlert = useAlert();
    const [fetchState, setParams] = useAxios();

    const handleLogin = (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        const data = {
            identifier: formData.get('username'),
            password: formData.get('password')
        }
        if (username && password) {
            setParams({ url: '/auth/login', method: 'POST', data });
        }
    };
    useEffect(() => {
        if(fetchState.data && !fetchState.isLoading && !fetchState.isError){
            onSuccessfulLogin(fetchState.data);
        }
    }, [fetchState])

    const onSuccessfulLogin = (data) => {
        if (data.user.role === 'admin') {
            localStorage.setItem('tk', data.jwt);
            navigate("/content", { replace: true });
        } else {
            showAlert('Access denied!', 'danger');
        }
    }

    return (
        <Fragment>
            <Container className="h-100">
                {/*{fetchState.isError && <Alert className={'w-50 position-absolute mt-3 start-50 translate-middle-x'} variant={"danger"}>{"There is some error!"}</Alert>}*/}
                <Row className="h-100 align-items-center">
                    <Col xs={12} md={{ span: 6, offset: 3 }} lg="6" >
                        <Card>
                            <Card.Body>
                                <Card.Title as="h3" className='mb-3'>Login to Fortuna</Card.Title>
                                <Form onSubmit={handleLogin}>
                                    <Form.Group controlId="formBasicEmail" className='mb-3'>
                                        <Form.Label>Username</Form.Label>
                                        <Form.Control type="text" name='username' placeholder="Username" onChange={(e) => setUsername(e.target.value)}/>
                                    </Form.Group>

                                    <Form.Group controlId="formBasicPassword">
                                        <Form.Label>Password</Form.Label>
                                        <Form.Control type="password" name='password' placeholder="Password" onChange={(e) => setPassword(e.target.value)}/>
                                    </Form.Group>
                                    <Button variant="primary" type="submit" className="w-100 mt-4">
                                        {
                                            fetchState.isLoading ?
                                                <Spinner animation="border" size="sm"/> :
                                                "Login"
                                        }
                                    </Button>
                                </Form>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
};

export default Login;
