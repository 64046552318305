import React, { useEffect } from "react";
import Layout from "../../components/common/layout/Layout";
import { Button, Form, Spinner } from "react-bootstrap";
import { useAxios } from "../../customHooks/useAxios";
import { useAlert } from "../../components/common/alertProvider/AlertProvider";

const Notification = () => {
  const [fetchState, setParams] = useAxios();
  const showAlert = useAlert();
  const sendNotification = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const data = Object.fromEntries(formData.entries());
    setParams({ url: "/send-push-notification", data, method: "POST" });
  };
  useEffect(() => {
    if (!fetchState.isLoading && !fetchState.isError && fetchState.data) {
      showAlert("Notification sent successfully!", "success");
    }
  }, [fetchState]);
  return (
    <Layout>
      <h3>Notification</h3>
      <Form onSubmit={sendNotification}>
        <Form.Group className="mt-3" controlId="notification-title">
          <Form.Label>Title</Form.Label>
          <Form.Control type="text" name={"title"} placeholder="Title" />
        </Form.Group>
        <Form.Group className="mt-3" controlId="notification-description">
          <Form.Label>Description</Form.Label>
          <Form.Control
            rows={5}
            as="textarea"
            name={"text"}
            placeholder="Description"
          />
        </Form.Group>
        <Button type="submit" className="mt-3 px-5">
          {fetchState.isLoading ? (
            <Spinner animation="border" size="sm" />
          ) : (
            "Send"
          )}
        </Button>
      </Form>
    </Layout>
  );
};

export default Notification;
