export const DROPDOWN_ITEMS = [
  {
    id: 1,
    title: "News",
    name: "news",
    eventName: 'createNews'
  },
  {
    id: 2,
    title: "Market Insights",
    name: "marketInsights",
    eventName: 'createMarketInsights'
  },
  {
    id: 3,
    title: "Week Ahead",
    name: "weekAhead",
    eventName: 'createWeakAhead'
  },
  {
    id: 4,
    title: "Analysis",
    name: "analysis",
    eventName: 'createAnalysis'
  },
];

export const locationNames = {
  "/content": "Content",
  "/trading-ideas": "Trade Ideas",
  "/notification": "Notification",
}