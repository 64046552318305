import React, {createContext, useContext, useEffect, useState} from "react";
import {Alert} from "react-bootstrap";

export const AlertContext = createContext(null);

const AlertProvider = props => {
    let visibilityTimeout = null;
    const [alert, setAlert] = useState(null);
    const onToastFinished = id => setAlert(alert.filter(item => item.id !== id));
    const showAlert = (message, type = 'danger', delay = 5000) => {
        clearTimeout(visibilityTimeout);
        setAlert({
            id: Date.now(),
            message: message,
            delay: delay,
            type: type
        });
    }
    useEffect(() => {
        if(alert && alert?.message){
            visibilityTimeout = setTimeout(() => {
                setAlert(null);
            }, alert.delay);
        }
    }, [alert]);
    return (
        <AlertContext.Provider value={showAlert} {...props}>
            <Alert role={'button'}
                   show={alert}
                   onClose={() => setAlert(null)}
                   dismissible
                   className={`w-50 position-absolute mt-3 start-50 translate-middle-x`}
                   variant={alert?.type}>
                {alert?.message}
            </Alert>
            {props.children}
        </AlertContext.Provider>
    );
};

export const useAlert = () => useContext(AlertContext);

export default AlertProvider;