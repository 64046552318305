import React, {useState, useRef, useEffect} from "react";
import JoditEditor from "jodit-react";
import "./textEditor.css";

const TextEditor = (props) => {
  const editorRef = useRef();
  const [value, setValue] = useState(props.defaultValue || "");
  const token = localStorage.getItem("tk");
  const config = {
      readonly: false,
      enableDragAndDropFileToEditor: true,
      "uploader": {
          url: 'https://api-main.fortuna.investments/upload-file',
          headers: {
              "Authorization": `Bearer ${token}`
          },
          pathVariableName: false,
          filesVariableName: () => 'files.file',
          data: {
              data: '{}'
          },
          isSuccess: () => true,
          process: function (resp) {
              return {
                  files: [resp.file[0].url],
                  isImages: [true],
                  baseurl: 'https://api-main.fortuna.investments',
                  error: 'errrr',
                  msg: 'resp[0].size'
              };
          },
      },
  };

  useEffect(() => {
      if(props.direction === 'rtl'){
          editorRef.current.parentElement.style.textAlign = 'right';
          editorRef.current.parentElement.style.direction = 'rtl';
      } else {
          editorRef.current.parentElement.style.textAlign = 'left';
          editorRef.current.parentElement.style.direction = 'ltr';
      }
  }, [props])
  return (
    <div>
      <JoditEditor
        name={'description'}
        ref={editorRef}
        value={value}
        config={config}
        tabIndex={1}
        onBlur={(newContent) => setValue(newContent)}
      />
    </div>
  );
};

export default TextEditor;
