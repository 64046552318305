import React from "react";
import { Form } from "react-bootstrap";

const SelectBox = ({options, name, defaultValue, ...htmlProps}) => {
  return (
    <div {...htmlProps}>
      <Form.Select
        aria-label="Default select example"
        name={name}
        // onChange={(e) => handleChange(options.find(item => item.id === +e.target.value))}
      >
        {options?.map((item) => (
          <option value={item.id} selected={defaultValue && defaultValue === item.id} key={item.id}>{item.title}</option>
        ))}
      </Form.Select>
    </div>
  );
};

export default SelectBox;
